import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import App from './containers/App';
import * as serviceWorker from './utilities/serviceWorker';
import history from './utilities/history';

render(
  <Router 
    basename={ process.env.PUBLIC_URL } 
    history={ history }>
    <App />
  </Router>, 
  document.getElementById('root')
);

serviceWorker.unregister();
