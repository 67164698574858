import React, { createContext, useContext, useState, useEffect } from 'react';

const WindowContext = createContext(null);

const WindowProvider = ({ children }) => {
  const [ dimensions, setDimensions ] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    };

    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  }, []);

  return (
    <WindowContext.Provider value={ dimensions }>
      { children }
    </WindowContext.Provider>
  )
}

export default WindowProvider;

export const useWindow = () => useContext(WindowContext);