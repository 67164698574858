import { useQuery, queryCache } from 'react-query';
import axios from 'axios';
import adapter from 'axios/lib/adapters/http';
import is from 'is_js';

export const useAllPages = () => {
  return useQuery('all-pages', () => axios.get(`http://ampcms.dickiesarena.live/wp-json/acf/v3/pages`, { adapter })
    .then(res => {
      if (is.array(res.data)) {
        let pagesObj = {};

        res.data.forEach(page => {
          if (page.acf.hasOwnProperty('landing_seat_link')) {
            pagesObj.landing = page.acf;
          } else if (page.acf.hasOwnProperty('section_1')) {
            pagesObj.about = page.acf;
          } else if (page.acf.hasOwnProperty('guest_services_header')) {
            pagesObj.guest = page.acf;
          }
        });

        return pagesObj;
      }
      throw new Error('all-pages failed');
    })
    .catch(err => true),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

export const refetchPages = () => {
  queryCache.invalidateQueries('all-pages');
}
