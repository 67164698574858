import React from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import fbIcon from '../assets/img/icon-facebook.png';
import twitterIcon from '../assets/img/icon-twitter.png';
import instaIcon from '../assets/img/icon-insta.png';

const duration = 100;

const defaultStyle = {
  transition: `opacity ${ duration }ms ease-in-out`,
  opacity: 0,
  display: 'none',
};

const transitionStyles = {
  entering: { display: 'block' },
  entered: { opacity: 1, display: 'block' },
  // exiting: { opacity: '50%' },
  exited: { opacity: 0, display: 'none' }
};

const navLinks = [
  { 
    name: 'Home',
    id: 'landing',
    path: '/'
  },
  { 
    name: 'Directions',
    id: 'directions',
  },
  { 
    name: 'Seating Chart',
    id: 'seating-chart',
  },
  { 
    name: 'Concourse Map',
    id: 'concourse-map',
  },
  { 
    name: 'Concessions',
    id: 'concessions',
  },
  { 
    name: 'About',
    id: 'about',
  },
]

export default function HeaderNav({ open, handleClose }) {
  // if (open) {
    return (
      <Transition in={ open } timeout={ duration }>
        { state => (
          <div 
            className='header__nav'
            style={{
              ...defaultStyle,
              ...transitionStyles[ state ],
            }}>
            { navLinks.map( link =>
              <NavLink 
                key={ link.id }
                data-testid={ `nav-link-${ link.id }` }
                id={ `nav-link-${ link.id }` }
                to={ `/${ link.id !== 'landing' ? link.id : '' }` }
                exact
                activeClassName='active'
                onClick={ handleClose }>
                { link.name }
              </NavLink>
            ) }

            <NavLink 
              data-testid='nav-link-guest-services'
              id='nav-link-guest-services'
              to='/guest-services'
              activeClassName='active' 
              onClick={ handleClose }>
              Guest Services
            </NavLink>

            <NavLink 
              data-testid='nav-link-upcoming-events'
              id='nav-link-upcoming-events'
              to='/upcoming-events'
              activeClassName='active' 
              onClick={ handleClose }>
              Upcoming Events
            </NavLink>

            <div className='header__nav__social'>
              <a 
                href='https://www.facebook.com/dickiesarena/' 
                target='_blank'
                rel='noopener noreferrer'>
                <img src={ fbIcon } alt='Facebook' />
              </a>

              <a 
                href='https://twitter.com/DickiesArena'
                target='_blank'
                rel='noopener noreferrer'>
                <img src={ twitterIcon } alt='Twitter' />
              </a>

              <a 
                href='https://www.instagram.com/dickiesarena/?hl=en'
                target='_blank'
                rel='noopener noreferrer'>
                <img src={ instaIcon } alt='Instagram' />
              </a>
            </div>
          </div>
        )}
      </Transition>
    )
  // } else {
  //   return null;
  // }
}

HeaderNav.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
