import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import is from 'is_js';
import { ReactQueryDevtools } from 'react-query-devtools';
import '../styles/app.scss';

import WindowProvider from '../hooks/use-window';
import { useAllPages } from '../hooks/use-pages';
import Routes from './Routes';
import Header from '../components/Header';
import LogoLoading from '../components/loaders/LogoLoading';

export default function App() {
  const { data: pages } = useAllPages();
  
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      ReactGA.initialize('UA-72166031-24', { testMode: process.env.NODE_ENV === 'test' });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <WindowProvider>
      { process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools 
          initialIsOpen={ false }
          position='bottom-right' />
      ) }
      
      <div id='app' className='app' data-testid='app-wrapper'>
        <Header />

        <div id='header-gap'></div>

        { is.object(pages) ? (
          <Routes pages={ pages } />
        ) : (
          <LogoLoading />
        ) }
      </div>
    </WindowProvider>
  );
}
