import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useUpcomingEvents } from '../hooks/use-events';

const SlideFooter = ({ acf }) => {
  return (
    <div className='slide__footer'>
      <div className='carousel__copy'>
        { acf.event_title }
      </div>

      { is.existy(acf.event_date_end) ? (
        <div className='carousel__date'>
          { format(acf.event_date, 'MMM d') }
          <span style={{ margin: 'auto 5px' }}>-</span>
          { format(acf.event_date_end, 'MMM d, yyy') }
          { is.existy(acf.event_time_text_override) ? <span style={{ fontSize: '0.8em' }}> - { acf.event_time_text_override } </span> : null }
        </div>
      ) : (
        <div className='carousel__date'>
          { is.existy(acf.event_time_text_override) ? (
            `${ format(acf.event_date, 'MMM d, yyy') } ${ acf.event_time_text_override }` 
          ) : (
            format(acf.event_date_time, 'EEE, MMM d - h:mm a') 
          ) }
        </div>
      ) }
    </div>
  )
};

SlideFooter.propTypes = {
  acf: PropTypes.shape({
    event_title: PropTypes.string,
    event_end_date: PropTypes.instanceOf(Date),
    event_date: PropTypes.instanceOf(Date),
    event_time: PropTypes.instanceOf(Date)
  }).isRequired,
}

export default function LandingCarousel() {
  const { data: upcomingEvents } = useUpcomingEvents();
  const [ featuredEvents, setFeaturedEvents ] = useState();

  useEffect(() => {
    if (upcomingEvents) {
      setFeaturedEvents(upcomingEvents.slice(0, 3));
    }
  }, [ upcomingEvents ]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
  };

  return (
    <Slider { ...settings }>
      { is.array(featuredEvents) && featuredEvents.map(event => 
        <div className='home-slide' key={ event ? event.id : 'no-event' }>
          <div 
            className='blurred-image' 
            style={ { backgroundImage: `url(${ event.acf.event_image.url })` } }>
          </div>
          
          <div className='content'>
            <h1 className='carousel__top'>
              Dickies Arena <span className='white'>Live!</span> Presents
            </h1>

            <div className='slide__image slide__image__mobile'>
              <Link to={ `/event/${ event.id }` }>
                <img src={ event.acf.event_big_image.url } alt={ event.acf.event_title } />
              </Link>
            </div>

            <div className='slide__image slide__image__tablet'>
              <Link to={ `/event/${ event.id }` }>
                <img src={ event.acf.event_image.url } alt={ event.acf.event_title } />
              </Link>
            </div>

            <SlideFooter acf={ event.acf } />
          </div>
        </div>
      ) }
    </Slider>
  )
}
