import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/Footer';
import LandingCard from '../components/LandingCard';
import LandingCarousel from '../components/LandingCarousel';

export default function Landing({ fields }) {
  const [ cards, setCards ] = useState([]);

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
    if (fields) {
      const landingCards = [
        { path: '/seating-chart', image: fields.landing_seat_link },
        { path: '/concourse-map', image: fields.landing_concession_link },
        { path: '/about', image: fields.landing_about_link },
        { path: '/directions', image: fields.landing_map_link },
        { path: '/guest-services', title: 'Guest Services' },
        { path: '/upcoming-events', title: 'Upcoming Events' }
      ];
      setCards(landingCards);
    }
  }, [ fields ]);

  return (
    <div className='landing' data-testid='landing-page'>
      <div className='landing__flex'>
        <div className='landing__slider'>
          <LandingCarousel fields={ fields } />
        </div>

        <div className='landing__body'>
          <h2>Explore Dickies Arena Live</h2>

          <div className='landing__body__cards'>
            { cards.map((card, index) => (
              <LandingCard card={ card } key={ index } />
            )) }
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

Landing.propTypes = {
  fields: PropTypes.shape({
    landing_seat_link: PropTypes.object.isRequired,
    landing_concession_link: PropTypes.object.isRequired,
    landing_about_link: PropTypes.object.isRequired,
    landing_map_link: PropTypes.object.isRequired
  })
}
