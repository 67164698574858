import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/a-rena-logo.png';
import fbIcon from '../assets/img/icon-facebook.png';
import twitterIcon from '../assets/img/icon-twitter.png';
import instaIcon from '../assets/img/icon-insta.png';

export default function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__col'>
        <div className='footer__logo'>
          <Link to='/' id='footer-logo-link'>
            <img src={ logo } alt='Dickies Arena' />
          </Link>
        </div>        
      </div>

      <div className='footer__col'>
        <div className='footer__address'>
          1911 Montgomery St, Fort Worth, TX 76107  |  (817) 402-9000
        </div>

        {/* <div className='footer__phone'>
          (817) 402-9000
        </div> */}
      </div>
      
      <div className='footer__col'>
        <div className='footer__social'>
          <a 
            href='https://www.facebook.com/dickiesarena/' 
            target='_blank'
            rel='noopener noreferrer'>
            <img src={ fbIcon } alt='Facebook' />
          </a>

          <a 
            href='https://twitter.com/DickiesArena' 
            target='_blank' 
            rel='noopener noreferrer'>
            <img src={ twitterIcon } alt='Twitter' />
          </a>

          <a 
            href='https://www.instagram.com/dickiesarena/?hl=en' 
            target='_blank' 
            rel='noopener noreferrer'>
            <img src={ instaIcon } alt='Instagram' />
          </a>
        </div>
      </div>
    </footer>
  )
}
