import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import logo from '../assets/img/a-rena-logo.png';

export default function Header() {
  const [ menuOpen, setMenuOpen ] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      const appEl = document.getElementById('app');
      appEl && appEl.classList.remove('fixedHeader');
    }
  }, [ menuOpen ]);

  return (
    <header className='header'>
      <div className='header__top'>
        <div className='header__logo'>
          <Link to='/' id='header-logo-link'>
            <img src={ logo } alt='Dickies Arena' />
          </Link>
        </div>

        <div
          id='nav-burger'
          data-testid='nav-burger'
          className={ menuOpen ? 'open' : 'closed' } 
          onClick={ () => setMenuOpen(menuOpen => !menuOpen) }>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <Nav 
        open={ menuOpen } 
        handleClose={ () => setMenuOpen(false) } />
    </header>
  )
}
