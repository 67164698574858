import React from 'react';
import PropTypes from 'prop-types';
import loading from '../../assets/img/a-rena-logo.png';

export default function LogoLoading({ inner = false }) {
  return (
    <div className={ inner ? 'loading inner' : 'loading' }>
      <img
        className='animated infinite pulse'
        src={ loading }
        alt='Dickies Arena' />
    </div>
  )
}

LogoLoading.propTypes = {
  inner: PropTypes.bool
}
