import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import is from 'is_js';
import LogoLoading from '../components/loaders/LogoLoading';
import Landing from './Landing';

const About = lazy(() => import('./About'));
const GuestServies = lazy(() => import('./GuestServices'));
const ParkingMap = lazy(() => import('./guest-services/ParkingMap'));
const ADAServices = lazy(() => import('./guest-services/ADAServices'));
const Upcoming = lazy(() => import('./Upcoming'));
const Directions = lazy(() => import('./Directions'));
const SeatingChart = lazy(() => import('./SeatingChart'));
const ConcourseMap = lazy(() => import('./ConcourseMap'));
const Concessions = lazy(() => import('./Concessions'));
const SingleConcession = lazy(() => import('./SingleConcession'));
const EventDetail = lazy(() => import('./EventDetail'));
const PageNotFound = lazy(() => import('./PageNotFound'));

const Schedule = lazy(() => import('./events/rodeo/Schedule'));
const Athletes = lazy(() => import('./events/rodeo/Athletes'));
const Brackets = lazy(() => import('./events/rodeo/Brackets'));

// const LocationDisplay = withRouter(({ location }) => (
//   <div data-testid='location-display' style={{ display: 'none' }}>
//     { location.pathname }
//   </div>
// ));

export default function Routes({ pages }) {
  const baseRoutes = [
    // generic paths
    { path: '/', comp: Landing, fields: pages.landing },
    { path: '/about', comp: About, fields: pages.about },
    { path: '/guest-services', comp: GuestServies, fields: pages.guest },
    { path: '/guest-services/parking-map', comp: ParkingMap, fields: pages.guest },
    { path: '/guest-services/ada-services', comp: ADAServices, fields: pages.guest },
    { path: [ '/upcoming-events', '/upcoming' ], comp: Upcoming },
    { path: '/directions', comp: Directions },
    { path: '/seating-chart', comp: SeatingChart },
    { path: [ '/concourse-map', '/concourse' ], comp: ConcourseMap },
    { path: '/concessions', comp: Concessions },
    { path: '/concessions/:concession_id', comp: SingleConcession },
    { path: '/event/:event_id', comp: EventDetail },
    // rodeo sub pages
    { path: '/event/:event_id/schedule', comp: Schedule },
    { path: '/event/:event_id/athletes', comp: Athletes },
    { path: '/event/:event_id/brackets', comp: Brackets },
  ];

  return (
    <Suspense fallback={ <LogoLoading /> }>
      <Switch>
        { baseRoutes.map(option => {
          if (is.propertyDefined(option, 'fields')) {
            return (
              <Route
                exact
                key={ option.path }
                path={ option.path }
                render={ () => <option.comp fields={ option.fields } /> } />
            )
          } else {
            return (
              <Route
                exact
                key={ option.path }
                path={ option.path }
                render={ () => <option.comp /> } />
            )
          }
        }) }

        <Route path='*'>
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  )
}
