import { useQuery, queryCache } from 'react-query';
import axios from 'axios';
import adapter from 'axios/lib/adapters/http';
import { parse, isToday, isFuture, compareAsc } from 'date-fns';
import is from 'is_js';

function parseEvent(event) {
  let endDate = parse(event.acf.event_date, 'dd/MM/yyyy', new Date());
  let dateTime = parse(`${ event.acf.event_date} ${ event.acf.event_time }`, 'dd/MM/yyyy HH:mm:ss', endDate);
  
  event.acf.event_date = endDate;
  event.acf.event_date_time = dateTime;
  event.acf.event_time = parse(event.acf.event_time, 'HH:mm:ss', endDate);

  if (event.acf.event_date_end) {
    event.acf.event_date_end = parse(event.acf.event_date_end, 'dd/MM/yyyy', dateTime);
  }
  return event;
}

export const useEvent = (event_id) => {
  return useQuery(['event', event_id ], () => axios.get(`http://ampcms.dickiesarena.live/wp-json/wp/v2/event/${ event_id }`, { adapter })
    .then(res => {
      return parseEvent(res.data);
    })
    .catch(err => true),
    {
      enabled: is.existy(event_id)
    }
  );
}

export const useCurrentEvent = () => {
  return useQuery('current-event', () => axios.get('http://ampcms.dickiesarena.live/wp-json/wp/v2/event?per_page=100', { adapter })
    .then(res => {
      let currentEvent = res.data.find(event => {
        let eventDate = parse(event.acf.event_date, 'dd/MM/yyyy', new Date());
        return isToday(eventDate);
      });

      if (currentEvent) {
        return parseEvent(currentEvent);
      }
    })
    .catch(err => true)
  );
}

export const useUpcomingEvents = () => {
  return useQuery('upcoming-events', () => axios.get('http://ampcms.dickiesarena.live/wp-json/wp/v2/event?per_page=100', { adapter })
    .then(res => {
      let parsedEvents = res.data.map(event => parseEvent(event));

      let filteredEvents = parsedEvents.filter(event => {
        const eventDate = event.acf.event_date;
        return isToday(eventDate) || isFuture(eventDate);
      });

      let sortedEvents = filteredEvents.sort((a, b) => {
        return compareAsc(a.acf.event_date, b.acf.event_date);
      });
      
      return sortedEvents;
    })
    .catch(err => true)
  );
}

export const useOrderedEvents = (arrayOfEvents) => {
  return useQuery('use-ordered-events', () => Promise.all(arrayOfEvents.map(async event => {
      return await axios.get(`http://ampcms.dickiesarena.live/wp-json/wp/v2/event/${ event.ID }`, { adapter })
        .then(res => {
          // let parsedEvents = res.data.map(event => parseEvent(event));
          return parseEvent(res.data);
        })
        .catch(err => true);
    })),
    {
      enabled: is.array(arrayOfEvents) && is.not.empty(arrayOfEvents)
    }
  );
}

export const refetchEvents = () => {
  queryCache.invalidateQueries('event');
  queryCache.invalidateQueries('all-events');
  queryCache.invalidateQueries('upcoming-events');
}

